@import "../../sass/vars"

.btn-submit
  width: auto
  font: normal normal 600 18px/24px $main_font
  text-align: center
  background-color: $peri_pastel
  color: #ffffff
  cursor: pointer
  border: none
  padding: 12px 34px
  box-sizing: border-box
  box-shadow: 0 1px 1px #eeeeee
  margin: 10px 0
  vertical-align: middle
  border-radius: 24px
  transition: all 0.2s

  &:hover, &:focus
    background-color: #0C3E82CC
    cursor: pointer

  &:disabled
    background-color: #D9DCE2
    color: #707070