@import "../../sass/vars"

.form-input
  & > label
    font: normal normal normal 14px/21px $main_font
    max-width: 100%
    color: #0C3E82
    text-align: left
    margin: 0
    display: block
    transition: all 0.3s ease-in
    position: absolute
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    pointer-events: none
    top: calc(1px + .5 * 32px)
    left: 3px
    padding: 0
    z-index: 1

  & > input, & > textarea, .select__control, .PhoneInputInput, .react-datepicker__input-container > input
    margin: 0
    overflow: visible
    background-image: none
    background-clip: padding-box
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
    font-family: $main_font
    color: #0C3E82
    background-color: rgba(255,255,255,0)
    border-color: #B4E0EC
    border-width: 0 0 2px 0
    border-style: solid
    border-radius: 0
    width: 100%
    max-width: 100%
    font-size: 14px
    padding: 6px
    box-sizing: border-box
    outline: none
    font-weight: normal
    box-shadow: none
    height: 32px
    min-height: initial
    line-height: 1.3
  & > textarea
    height: auto
    &::placeholder
      color: $black
      opacity: 0.5
    
  &.active
    & > label
      top: 0 !important
      left: 0 !important
      padding: 0
      font-size: 12px

    & input, & > textarea, .select__control
      background-color: rgba(255,255,255,0)
      border-color: #66afe9
      color: $black
      box-shadow: none
      outline: none

    .select__menu
      z-index: 2

  .select__value-container
    padding: 0

  .select__control--is-focused
    box-shadow: none !important

  .select__indicator
    padding: 0

  .select__indicator-separator
    display: none

  .select__dropdown-indicator, .select__dropdown-indicator:hover
    color: #F4E300

  .select__input-container,.select__single-value
    margin: 0
    padding: 0
    color: $black

  &.input-phone > label
    left: 75px
    top: 20px

  .react-datepicker-popper
    z-index: 10