//fonts
$main_font: 'Open Sans', sans-serif


//colors
$peri_pastel: #0C3E82

$light-green: #F8F7E5

$black: #0C3E82
$white: #FFF

$shadow: 0 3px 6px rgba(0,0,0,0.16)
