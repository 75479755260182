@import "sass/vars"

*, *:before, *:after
  box-sizing: border-box
  outline: none


html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  vertical-align: baseline
  text-rendering: optimizeLegibility
  text-size-adjust: none

body
  padding: 0
  margin: 0
  font-family: $main_font
  color: $black
  font-weight: normal
  line-height: 1.2
  position: relative
  background: $white
  max-width: 100vw
  overflow-x: hidden
  word-break: break-word
  display: flex
  justify-content: center
  align-items: center

  .grecaptcha-badge
    position: absolute

#root
  max-width: 1200px
  flex-basis: 70%

  @media (max-width: 1400px)
    flex-basis: 100%

  @media (max-width: 768px)
    margin: 0 auto