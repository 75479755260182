@import "../../sass/vars"

.form-wrap
  width: 100%

.contactus-form
  display: grid
  grid-template-columns: repeat(12, 1fr)
  grid-auto-rows: max-content
  grid-gap: 0 2%

  & > div
    position: relative
    padding-top: 16px
    clear: both
    grid-column: span 6 / span 6
    margin-bottom: 15px

    &:nth-last-child(2),&:nth-last-child(1)
      grid-column: span 12 / span 12

    @media (max-width: 470px)
      grid-column: span 12 / span 12
      &:nth-last-child(1)
        margin: 0 auto


  .error
    width: 100%
    color: #B94A48
    font: normal normal normal 14px/21px $main_font
    padding-top: 5px
